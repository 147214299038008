@charset "utf-8";
#PrincipalSlides{
    margin: auto;
    padding: 0;
    display: block;
    width: 100%;
    /*max-width: 1200px;*/
    /*background-image: url("/images/principal_slides_background.webp");*/
	background-repeat: no-repeat;
	background-size: cover;
	text-align: center;
    cursor: pointer;
}
#ClickAbleSlide{
    margin: auto;
    padding: 0;
    display: block;
    width: 50vw;
    height: 26vw;
    position: absolute;
    top: initial;
    left: 50%;
    transform: translate(-50%, -31vw);
}
#CurrentSlideImage{
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
}
/*--------------------------------------------------------*/
/*--------------------------------------------------------*/
/*--------------------------------------------------------*/
#PrincipalSlides #PrincipalSlidesContainer{
    text-align: center;
    position: relative;
}
#PrincipalSlides #PrincipalSlidesContainer .SlidesMoveButton{
    margin: 0;
    padding: 9px 5px;
    display: block;
    position: absolute;
    top: 47%;
    /* bring your own prefixes */
    transform: translate(-50%, 0%);
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: .3s;
    color: #666666;
    border-radius: 5px;
    background-color: rgba(255,255,255,.4);
}
#PrincipalSlides #PrincipalSlidesContainer #PrincipalSlidesList{
    display: none;
}

/*--------------------------------------------------------*/
/*--------------------------------------------------------*/
/*--------------------------------------------------------*/
#PrincipalSlides #PrincipalSlidesContainer #SelectorPrincipalSlidesList{
    margin: 0;
    padding: 0;
    display: block;
    list-style: none;
    width: 100%;
    text-align: center;
    transform: translate(0, -200%);
}
#PrincipalSlides #PrincipalSlidesContainer #SelectorPrincipalSlidesList .SelectorPrincipalSlidesListItem{
    margin: 0 5px;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    background-color: #E6FAFE;
    width: 13px;
    height: 13px;
    border-radius: 13px;
    cursor: pointer;
    transition: .3s;
}
#PrincipalSlides #PrincipalSlidesContainer #SelectorPrincipalSlidesList .SelectorPrincipalSlidesListItem:hover,
#PrincipalSlides #PrincipalSlidesContainer #SelectorPrincipalSlidesList .SelectorPrincipalSlidesListItem:focus,
#PrincipalSlides #PrincipalSlidesContainer #SelectorPrincipalSlidesList .SelectorPrincipalSlidesListItemSelected{
    background-color: #FFFFFF !important;
}
@media screen and (min-width: 701px) {
    #PrincipalSlides{
    }
    /*#PrincipalSlides #PrincipalSlidesList{
        
    }*/
    #PrincipalSlides #PrincipalSlidesContainer .SlidesMoveButton{
        font-size: 1.4em;
        font-weight: bold;
    }
    #PrincipalSlides #PrincipalSlidesContainer #LeftSlidesMoveButton{
        left: 2%;
    
    }
    #PrincipalSlides #PrincipalSlidesContainer #RightSlideMoveButton{
        left: 97%;
    }
    
   
}
@media screen and (max-width: 700px) {
	#PrincipalSlides{
    }
    /*#PrincipalSlides #PrincipalSlidesList{
        
    }*/
    #PrincipalSlides #PrincipalSlidesContainer .SlidesMoveButton{
       display: none;
    }
    #PrincipalSlides #PrincipalSlidesContainer #LeftSlidesMoveButton{
        left: 2%;
    
    }
    #PrincipalSlides #PrincipalSlidesContainer #RightSlideMoveButton{
        left: 97%;
    }
    
}
